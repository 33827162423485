'use client';

import { useIsClient } from '@lib/hooks/useIsClient';
import { Link } from '@ui-components/index';
import { createPortal } from 'react-dom';

const DRAFT_MODE_BANNER_ID = 'draft-mode-banner-container';

export const DraftModeBannerContainer = () => <div id={DRAFT_MODE_BANNER_ID} />;

const DraftModeBanner = ({ livePageUrl }: { livePageUrl: string }) => {
  const isClient = useIsClient();

  return isClient
    ? createPortal(
        <div className="flex items-center justify-center border-b-2 border-white bg-black py-2 text-center text-white">
          <h1 className="mr-4">Preview Version</h1>
          <Link
            href={livePageUrl}
            className="inline-block rounded border-2 border-white px-2 py-1 text-sm uppercase text-white hover:underline"
          >
            View live page
          </Link>
        </div>,
        document.getElementById(DRAFT_MODE_BANNER_ID)
      )
    : null;
};

export default DraftModeBanner;
