'use client';

import { usePathname } from 'next/navigation';
import Script from 'next/script';

type Props = {
  dataDomain?: string;
};

export default function CookieConsent({ dataDomain }: Props) {
  const pathname = usePathname();

  if (pathname?.includes('/preview/')) {
    return null;
  }

  return (
    <>
      <Script
        id="oneTrustSDK"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        data-domain-script={dataDomain}
        data-dLayer-ignore="true"
        type="text/javascript"
        strategy="beforeInteractive"
      />
      <Script
        id="oneTrustOptanon"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper(){}`,
        }}
      />
    </>
  );
}
